
import mixins from 'vue-typed-mixins';
import { required, minValue, sameAs } from 'vuelidate/dist/validators.min';

import DepositData from 'Modules/Payment/components/Deposit/Deposit.Data.vue';
import { hasManifest, getManifest } from 'Models/assetsManifest';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import { Account } from 'Models/accounts';
import numberFormater from 'Mixins/numberFormater';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import Balance from 'Entities/privatePresenter/Balance';
import ModuleBlocker from 'UI/ModuleBlocker.vue';
import Button from 'Control/Button.vue';
import AssetsBlockchainsDropdown from 'Control/AssetsBlockchainsDropdown.vue';
import Checkbox from 'Control/Checkbox.vue';
import ValueBlock from 'Modules/Payment/components/ValueBlock.vue';
import MoneyInput from 'Control/MoneyInput.vue';
import TextInput from 'Control/TextInput.vue';
import BankRequisitesResponseData from 'Entities/walletExecutor/BankRequisitesResponseData';
import Asset, { IAsset } from 'Entities/publicPresenter/Asset';

interface Data {
    hasManifest: any;
    getManifest: any;
    isUserAgree: boolean;
    quantity: number;
    hasAddressError: boolean;
    hasRequisitesError: boolean;
    hasCheckboxError: boolean;
    quantityButtons: any[];
    showTooltip: boolean;
    DEFAULT_QUANTITY_MIN_VALUE: string;
}

interface Methods {
    shortAddress: (data: string) => string;
    openWhitelisting: () => void;
    openRequisitesWhitelisting: () => void;
    setQuantityPartOfFree: (data: number) => void;
    setQuantityMinSize: () => void;
    performDeposit: () => void;
    showErrorNotification: () => void;
    setQuantity: (data: number) => void;
    setCurrency: (data: number) => void;
    setAddress: (data: number) => void;
    setRequisite: (data: number) => void;
    setBlockchain: (data: number) => void;
    toggleDemoAccountAlert: () => void;
    initPresetedValues: () => void;
}

interface Computed {
    currentAddress: UserTransferAddress;
    currentRequisite: BankRequisitesResponseData;
    activeAccountId: string;
    currentCurrencySymbol: string;
    addressesList: UserTransferAddress[];
    requisitesList: BankRequisitesResponseData[];
    filteredRequisitesList: BankRequisitesResponseData[];
    activeAddressIndex: number;
    activeRequisiteIndex: number;
    activeCurrencyIndex: number;
    activeBlockchainIndex: number;
    activeAccount: Account[];
    currentUser: InternalUserResponse;
    isKycVerified: boolean;
    disabledQuantityButtonsIndexes: number[];
    currentBalance: Balance | undefined;
    transferMinSize: number;
    quantityErrorText: string;
}

export default mixins(DepositData).extend<Data, Methods, Computed, any>({
    name: 'PaymentDeposit',
    mixins: [numberFormater, DepositData],
    components: {
        TextInput,
        ModuleBlocker,
        Button,
        AssetsBlockchainsDropdown,
        Checkbox,
        ValueBlock,
        MoneyInput,
    },
    props: {
        isDisabled: {
            type: Boolean,
            default: false,
        },
        presetedQuantity: {
            type: Number,
            default: undefined,
        },
        presetedCurrencyIndex: {
            type: Number,
            default: undefined,
        },
        presetedBlockchainIndex: {
            type: Number,
            default: undefined,
        },
        presetedAddressIndex: {
            type: Number,
            default: undefined,
        },
        presetedRequisiteIndex: {
            type: Number,
            default: undefined,
        },
    },
    data() {
        return {
            hasManifest,
            getManifest,
            isUserAgree: false,
            quantity: 0,
            hasAddressError: false,
            hasRequisitesError: false,
            hasCheckboxError: false,
            showTooltip: true,
            DEFAULT_QUANTITY_MIN_VALUE: '0.00000001',
            quantityButtons: [
                {
                    title: 'Min',
                    callback: () => this.setQuantityMinSize(),
                },
                {
                    title: '25%',
                    callback: () => this.setQuantityPartOfFree(0.25),
                },
                {
                    title: '50%',
                    callback: () => this.setQuantityPartOfFree(0.5),
                },
                {
                    title: '75%',
                    callback: () => this.setQuantityPartOfFree(0.75),
                },
                {
                    title: 'Max',
                    callback: () => this.setQuantityPartOfFree(1),
                },
            ],
        };
    },
    validations() {
        return {
            currentAddress: {
                required: this.GET_IS_SELECTED_ASSET_FIAT ? false : required,
            },
            currentRequisite: {
                required: this.GET_IS_SELECTED_ASSET_FIAT ? required : false,
            },
            isUserAgree: {
                sameAs: sameAs(() => true),
            },
            quantity: {
                minValue: minValue(this.transferMinSize),
            },
        };
    },
    computed: {
        quantityErrorText() {
            if (!this.$v.quantity.minValue) {
                return `Quantity must be greater than ${this.transferMinSize}`;
            }
            return '';
        },
        transferMinSize() {
            return this.assets.get(this.depositUi.asset)?.transferDetails
                ?.find((d) => d.blockchainName === this.depositUi.blockchain)?.transferMinSize ?? 0;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        currentAddress() {
            return this.depositUi.address;
        },
        currentRequisite() {
            return this.filteredRequisitesList[this.activeRequisiteIndex];
        },
        activeAccountId() {
            return this.$store.state.Accounts.activeAccountID;
        },
        currentCurrencySymbol() {
            if (this.depositUi.asset) {
                return this.depositUi.asset;
            }
            return '';
        },
        addressesList() {
            if (this.addresses && this.addresses.length > 0) {
                return this.addresses;
            }
            return [{ address: 'No whitelisted addresses', isEmpty: true }];
        },
        requisitesList() {
            if (this.requisites && this.requisites.length > 0) {
                return this.requisites;
            }
            return [{ alias: 'No whitelisted bank accounts', isEmpty: true }];
        },
        filteredRequisitesList() {
            return this.requisitesList.filter(({ asset }) => asset === this.currentCurrencySymbol);
        },
        activeAddressIndex: {
            get() {
                return this.depositUi.currentAddressIndex;
            },
            set(index) {
                this.SET_ACTIVE_ADDRESS(index);
            },
        },
        activeRequisiteIndex: {
            get() {
                return this.depositUi.currentRequisiteIndex;
            },
            set(index) {
                this.SET_ACTIVE_REQUISITE(index);
            },
        },
        activeCurrencyIndex: {
            get() {
                return this.depositUi.currentAssetIndex;
            },
            set(index) {
                this.depositUi.currentAssetIndex = index;
                this.depositUi.asset = Array.from(this.assets.keys())[index];
            },
        },
        activeBlockchainIndex: {
            get() {
                return this.depositUi.currentBlockchainIndex;
            },
            set(index) {
                this.depositUi.currentBlockchainIndex = index;
                this.depositUi.blockchain = Array.from(this.blockchains.keys())[index];
            },
        },
        activeAccount() {
            return this.$store.state.Accounts.accounts.filter((a) => a.id === this.activeAccountId);
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        disabledQuantityButtonsIndexes() {
            const result: number[] = [];
            if (!this.currentBalance) {
                return [1, 2, 3, 4];
            }
            if (this.currentBalance!.free * 0.25 < this.transferMinSize) {
                result.push(1);
            }
            if (this.currentBalance!.free * 0.5 < this.transferMinSize) {
                result.push(2);
            }
            if (this.currentBalance!.free * 0.75 < this.transferMinSize) {
                result.push(3);
            }
            if (this.currentBalance!.free < this.transferMinSize) {
                result.push(4);
            }
            return result;
        },
        currentBalance() {
            return this.$store.state.Balances.balances.find((b :Balance) => {
                return b.placementName === 'Single Broker'
                    && b.assetSymbol === this.depositUi.asset
                    && (b.blockchainName === this.depositUi.blockchain || b.assetType === 'fiat')
                    && b.accountId === this.activeAccountId;
            });
        },
    },
    methods: {
        toggleDemoAccountAlert() {
            if (this.$store.getters['Accounts/isActiveAccountDemo']) {
                this.$store.state.Accounts.showDemoAccountAlert = true;
                this.$store.commit('Accounts/SET_IS_DEMO_ACCOUNT_SHAKING');
            }
        },
        setQuantity(num) {
            this.quantity = this.truncateNumber(num, 8);
        },
        shortAddress(string: string) {
            if (string.toLowerCase() === 'no whitelisted addresses') {
                return string;
            }
            return `${string[0] + string[1] + string[2]}...${string[string.length - 3]}${string[string.length - 2]}${string[string.length - 1]}`;
        },
        async openWhitelisting() {
            await this.prepareWhitelisting();
            await this.$router.push({
                path: '/Wallets/Confirmations/Whitelisting',
                query: {
                    assets: this.whitelistingData.assets.map((a) => a.serialize()),
                    allBlockchains: this.whitelistingData.blockchains.map((b) => b.serialize()),
                    previousRoute: this.$router.currentRoute.path,
                    selectedBlockchain: this.depositUi.blockchain,
                    selectedAsset: this.depositUi.asset,
                },
            });
        },
        async openRequisitesWhitelisting() {
            await this.$router.push({
                path: '/Wallets/Confirmations/requisites-whitelisting',
                query: {
                    previousRoute: this.$router.currentRoute.path,
                    assets: JSON.stringify(Array.from(this.assets.values()).reduce((accum, current) => {
                        if ((current as Asset).type === 'fiat') {
                            (accum as IAsset[]).push((current as Asset).serialize());
                        }
                        return accum;
                    }, [] as IAsset[])),
                },
            });
        },
        setQuantityPartOfFree(factor) {
            if (this.currentBalance) {
                this.quantity = this.truncateNumber(this.currentBalance.free * factor, 8);
            } else {
                this.quantity = 0;
            }
        },
        setQuantityMinSize() {
            this.quantity = this.truncateNumber(this.transferMinSize, 8);
            if (Number(this.quantity) === 0) {
                this.quantity = this.DEFAULT_QUANTITY_MIN_VALUE;
            }
        },
        async performDeposit() {
            setTimeout(() => {
                this.showTooltip = true;
            }, 100);

            this.$v.$touch();
            this.hasAddressError = !this.currentAddress;
            this.hasRequisitesError = !this.currentRequisite;
            this.hasCheckboxError = !this.isUserAgree;
            if (!this.$v.$invalid) {
                if (this.activeAccount[0].placementLinks && this.activeAccount[0].placementLinks.find((l) => l === 'Single Broker')) {
                    await this.createDeposit(this.activeAccountId);
                    if (this.GET_IS_SELECTED_ASSET_FIAT) {
                        await this.$router.push({
                            path: '/Wallets/Confirmations/CompleteFiatDeposit',
                            query: {
                                depositUi: {
                                    ...this.depositUi,
                                    blockchain: this.depositUi.blockchain,
                                },
                                requisite: this.currentRequisite.serialize(),
                                previousRoute: this.$router.currentRoute.path,
                            },
                        });
                    } else {
                        await this.$router.push({
                            path: '/Wallets/Confirmations/CompleteDeposit',
                            query: {
                                depositUi: {
                                    ...this.depositUi,
                                    blockchain: this.depositUi.blockchain,
                                },
                                address: this.depositUi.address?.serialize(),
                                destinationAddress: this.depositUi.destinationAddress?.serialize(),
                                previousRoute: this.$router.currentRoute.path,
                            },
                        });
                    }
                } else {
                    this.showErrorNotification();
                }
            }
        },
        showErrorNotification() {
            this.$store.dispatch('Notificator/showErrorNotification', 'Your Account Is Still In Progress');
        },
        async setCurrency(index) {
            this.activeCurrencyIndex = index;
            this.quantity = 0;
            this.activeBlockchainIndex = 0;
            this.activeAddressIndex = 0;
            await this.getBlockchains();
            await this.getAddresses();
        },
        async setBlockchain(index) {
            this.activeBlockchainIndex = index;
            this.quantity = 0;
            this.activeAddressIndex = 0;
            await this.getAddresses();
        },
        async setAddress(index) {
            this.activeAddressIndex = index;
            this.quantity = 0;
        },
        async setRequisite(index) {
            this.activeRequisiteIndex = index;
            this.quantity = 0;
        },
        async initPresetedValues() {
            if (this.presetedCurrencyIndex !== undefined) {
                await this.setCurrency(this.presetedCurrencyIndex);
            }
            if (this.presetedBlockchainIndex !== undefined) {
                await this.setBlockchain(this.presetedBlockchainIndex);
            }
            if (this.presetedAddressIndex !== undefined) {
                await this.setAddress(this.presetedAddressIndex);
            }
            if (this.presetedRequisiteIndex !== undefined) {
                await this.setRequisite(this.presetedRequisiteIndex);
            }
            if (this.presetedQuantity !== undefined) {
                this.quantity = this.presetedQuantity;
            }
        },
    },
    async mounted() {
        await this.init();
        document.addEventListener('click', () => {
            this.showTooltip = false;
        });
        await this.getRequisites();
        await this.initPresetedValues();
    },
    watch: {
        async quantity(val) {
            await this.SET_AMOUNT(val);
            this.$emit('change-quantity', val);
            this.$v.quantity.$reset();
        },
        activeRequisiteIndex(val) {
            this.$emit('change-requisite', val);
        },
        activeAddressIndex(val) {
            this.$emit('change-address', val);
        },
        activeBlockchainIndex(val) {
            this.$emit('change-blockchain', val);
        },
        activeCurrencyIndex(val) {
            this.$emit('change-currency', val);
            this.setRequisite(0);
        },
    },
});
